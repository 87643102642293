import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import { Global } from '@emotion/core'

const globalStyles = {
  body: {
    color: '#333',
    padding: 0,
    margin: 0,
    lineHeight: 1.4,
    fontSize: 18,
    fontFamily: 'Alegreya Sans',
    fontWeight: 300
  },
  p: {
    margin: 0
  },
  'h1, h2, h3': {
    fontFamily: 'Alegreya',
    fontWeight: 400
  }
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <Global styles={globalStyles} />
        <Header
          siteTitle={data.site.siteMetadata.title}
          siteDescription={data.site.siteMetadata.description}
        />
        <main
          css={{
            margin: `0 auto`,
            maxWidth: '72ch',
            paddingTop: '3em',
            paddingBottom: '3em',
            paddingLeft: '1em',
            paddingRight: '1em',
          }}
        >
          {children}
        </main>
        <footer
          css={{
            padding: '3em',
            maxWidth: '60ch',
            margin: '0 auto',
            marginTop: '3em',
            borderTop: '1px solid hsl(0, 0%, 90%)',
            textAlign: 'center',
            paddingTop: '3em'
          }}
        >
          <p css={{ marginBottom: '2.75em' }}>
            © {new Date().getFullYear()}
            {` `}
            Machat – Salis & Comp. GmbH
          </p>
          <div css={{ marginBottom: '1em', textAlign: 'center' }}>
            <h3>Impressum</h3>
            <p mb={3}>
              <span title="Umsatzsteuernummer">ATU73780027</span> &middot;{' '}
              <span title="Firmenbuchnummer">FN 499919a</span> &middot;{' '}
              <span title="Firmenbuchgericht">Handelsgericht Wien</span>
            </p>
            <p>Parkring 18/2, 1010 Wien, Austria</p>
            <p>+43.676.9372999</p>
            <p>office@msc.insure</p>
            <p>msc.insure</p>
          </div>
          <p css={{ textAlign: 'center', fontWeight: 500 }}>
            Versicherungsvermittler in der Form Versicherungsmakler
            <br />
            und Berater in Versicherungsangelegenheiten.
          </p>
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
