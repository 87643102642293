import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Image from './image'

const Header = ({ siteTitle, siteDescription }) => (
  <header
    css={{
      marginBottom: `2.75rem`,
      margin: '0 auto',
      maxWidth: '58ch',
      textAlign: 'center'
    }}
  >
    <div
      css={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `3rem 1.5rem`
      }}
    >
      <div css={{ marginBottom: '1.5em' }}>
        <Image />
      </div>
      <h1
        css={{
          margin: 0,
          lineHeight: 1,
          marginBottom: '0.275em',
          textTransform: 'lowercase',
          fontFamily: 'Alegreya',
          fontVariantCaps: 'all-small-caps',
          letterSpacing: '0.01em',
          fontWeight: 400,
          fontSize: 32
        }}
      >
        <Link
          to="/"
          css={{
            textDecoration: `none`,
            color: 'black'
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <p css={{ fontSize: 18 }}>{siteDescription}</p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``
}

export default Header
